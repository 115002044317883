// initSentry needs to be the first import
import './initSentry';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { ensureIntlListFormat } from '@deepstream/ui/polyfill/ensureIntlListFormat';
import { initReactTranslations } from '@deepstream/ui/i18n';
import App from './App';

ensureIntlListFormat()
  .then(initReactTranslations)
  .then(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
  })
  .catch(err => Sentry.captureException(err));
