export enum FeedbackContext {
  POST_BID_DEADLINE_SUPPLIER_FEEDBACK = 'postBidDeadlineSupplierFeedback',
  AWARD_FLOW_BUYER_FEEDBACK = 'awardFlowBuyerFeedback',
}

export enum RatingType {
  FIVE_POINT_LIKERT_SCALE = 'fivePointLikertScale',
}

export type PostBidDeadlineSupplierFeedbackMeta = {
  rfxId: string;
  emailSendDate: Date;
  usingNewUserExperience: boolean;
};

export type AwardFlowBuyerFeedbackMeta = {
  rfxId: string;
};

export type FeedbackMeta = PostBidDeadlineSupplierFeedbackMeta | AwardFlowBuyerFeedbackMeta;
