import * as React from 'react';
import styled from 'styled-components';
import { Text, Box, Card, Heading, Flex, TextProps, CardProps, BoxProps, HeadingProps, FlexProps } from 'rebass/styled-components';
import { IconValue } from '@deepstream/common';
import { useTheme } from '../theme/ThemeProvider';
import { Icon, IconProps } from './icon/Icon';
import { Truncate } from './text/Truncate2';
import { IconButton } from './button/IconButton';
import { BorderedIcon } from './icon/BorderedIcon';
import { UppercaseText } from './text/UppercaseText';
import { Clamp2 } from './text/Clamp';

const Divider = styled(Box)`
  margin: 0;
  border: 0;
  height: 1px;
`;

export const PanelDivider: React.FC<BoxProps> = (props) => (
  <Divider
    as="hr"
    bg="lightGray"
    {...props}
  />
);

export const DEFAULT_PANEL_PADDING = 20;

export const PanelText: React.FC<TextProps> = React.forwardRef(({
  lineHeight = 1.5,
  p = DEFAULT_PANEL_PADDING,
  ...props
}, ref) => (
  <Text
    ref={ref}
    lineHeight={lineHeight}
    fontSize={2}
    p={p}
    {...props}
  />
));

export const HeadingDescriptionContainer = styled(Box)`
  color: ${props => props.theme.colors.subtext};
  font-size: ${props => props.theme.fontSizes[1]}px;
  margin-top: ${props => props.theme.space[1]}px;
`;

export const SeeMoreText = styled(Text)`
  display: inline;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes[1]}px;
`;

const HeadingDescription = (props: BoxProps) => {
  return (
    <HeadingDescriptionContainer
      {...props}
      sx={{
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
      }}
    >
      <Clamp2 lines={2}>
        {props.children}
      </Clamp2>
    </HeadingDescriptionContainer>
  );
};

export const PanelHeading: React.FC<{ icon?: IconValue; iconProps?: Partial<IconProps>; description?: React.ReactNode } & HeadingProps> = ({
  as = 'h2',
  textAlign = 'left',
  p = DEFAULT_PANEL_PADDING,
  fontSize = 4,
  icon,
  iconProps,
  description,
  ...props
}) => (
  <Flex alignItems="flex-start" p={p}>
    {icon && <BorderedIcon icon={icon} iconProps={iconProps} mr="20px" />}
    <Box as="hgroup">
      <Heading
        as={as}
        textAlign={textAlign}
        fontSize={fontSize}
        fontWeight={500}
        {...props}
      />
      {description && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <HeadingDescription as="p" sx={{ textAlign }}>
          {description}
        </HeadingDescription>
      )}
    </Box>
  </Flex>
);

export const SidebarPanelHeading = (props: TextProps) => (
  <UppercaseText
    fontWeight={500}
    style={{ letterSpacing: '0.083333em' }}
    {...props}
  />
);

export const PanelGroupHeader: React.FC<{ heading: string }> = ({ heading }) => (
  <PanelHeading p="0 0 16px">
    <Truncate>
      {heading}
    </Truncate>
  </PanelHeading>
);

export const PanelHeader: React.FC<{
  heading: React.ReactNode;
  description?: React.ReactNode;
  collapse?: boolean;
  icon?: IconValue;
  iconProps?: Partial<IconProps>;
} & FlexProps> = ({
  heading,
  description,
  collapse = true, // whether the children should move below heading at extra small width
  children,
  variant,
  sx,
  icon,
  iconProps,
  p = DEFAULT_PANEL_PADDING,
  ...props
}) => (
  <Flex
    bg={variant === 'subheader' ? 'lightGray3' : 'white'}
    flexDirection={collapse ? ['column', 'row'] : 'row'}
    alignItems={collapse ? ['flex-start', 'center'] : 'center'}
    justifyContent="space-between"
    sx={{ borderRadius: 'small', ...sx }}
    {...props}
  >
    <PanelHeading p={p} icon={icon} iconProps={iconProps} mr={collapse ? [0, 2] : 2} description={description}>
      <Truncate>
        {heading}
      </Truncate>
    </PanelHeading>
    {children && (
      <Box p={p} mb={collapse ? [20, 0] : 0} mt={collapse ? [-2, 0] : 0} sx={{ flexShrink: 0 }}>
        {children}
      </Box>
    )}
  </Flex>
);

export const CollapsiblePanelHeader: React.FC<{
  heading: React.ReactNode;
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
} & FlexProps> = ({
  heading,
  isCollapsed,
  setIsCollapsed,
  children,
  sx,
  ...props
}) => (
  <Flex
    bg="white"
    alignItems="center"
    sx={{
      borderRadius: 'small',
      p: DEFAULT_PANEL_PADDING,
      ...sx,
    }}
    {...props}
  >
    <Box mr={2}>
      <IconButton
        icon={isCollapsed ? 'chevron-right' : 'chevron-down'}
        onClick={() => setIsCollapsed(!isCollapsed)}
        fixedWidth
      />
    </Box>
    <PanelHeading mr={2} p={0}>
      <Truncate>
        {heading}
      </Truncate>
    </PanelHeading>
  </Flex>
);

export const PanelSubHeader: React.FC<{ heading?: any; collapse?: boolean } & FlexProps> = ({
  heading,
  collapse = true, // whether the children should move below heading at extra small width
  children,
  ...props
}) => (
  <Flex
    flexDirection={collapse ? ['column', 'row'] : 'row'}
    alignItems={collapse ? ['flex-start', 'center'] : 'center'}
    justifyContent="space-between"
    bg="lightGray3"
    {...props}
  >
    <PanelPadding>
      <SidebarPanelHeading mr={collapse ? [0, 2] : 2}>
        {heading}
      </SidebarPanelHeading>
    </PanelPadding>
    {children ? (
      <Box mx={20} mb={collapse ? [20, 0] : 0} mt={collapse ? [-2, 0] : 0}>
        {children}
      </Box>
    ) : (
      null
    )}
  </Flex>
);

const ExpandablePanelSubHeader: React.FC<{
  heading: React.ReactNode;
  isExpanded: boolean;
  setIsExpanded?: ((isExpanded: boolean) => void) | null;
} & FlexProps> = ({
  heading,
  isExpanded,
  setIsExpanded,
  children,
  sx,
  ...props
}) => {
  const theme = useTheme();
  const isExpandable = Boolean(setIsExpanded);

  return (
    <Flex
      alignItems="center"
      bg="lightGray3"
      sx={{
        minHeight: '52px',
        pl: isExpandable ? '14px' : DEFAULT_PANEL_PADDING,
        pr: DEFAULT_PANEL_PADDING,
        cursor: isExpandable ? 'pointer' : undefined,
        ':hover': isExpandable ? { background: theme.colors.lightGray3Hover } : undefined,
        ...sx,
      }}
      onClick={() => { setIsExpanded?.(!isExpanded); }}
      {...props}
    >
      {isExpandable && (
        <Icon
          icon={isExpanded ? 'chevron-down' : 'chevron-right'}
          fixedWidth
          color="subtext"
          mr={2}
        />
      )}
      <SidebarPanelHeading p={0} sx={{ flex: 1, textTransform: 'uppercase' }} lineHeight={1.5}>
        <Truncate>
          {heading}
        </Truncate>
      </SidebarPanelHeading>
      <Box sx={{ flex: '0 0 auto' }}>
        {children}
      </Box>
    </Flex>
  );
};

export const ExpandablePanelSubSection = ({
  heading,
  renderCollapsedContent,
  renderExpandedContent,
  ...props
}: {
  heading: React.ReactNode,
  renderCollapsedContent: () => React.ReactNode,
  renderExpandedContent?: () => React.ReactNode,
  bg?: string;
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <ExpandablePanelSubHeader
        heading={heading}
        isExpanded={isExpanded}
        setIsExpanded={renderExpandedContent ? setIsExpanded : null}
        {...props}
      >
        {!isExpanded && renderCollapsedContent()}
      </ExpandablePanelSubHeader>
      {isExpanded && (
        <>
          <PanelDivider />
          {renderExpandedContent?.()}
        </>
      )}
    </>
  );
};

export const SidebarPanelHeader: React.FC<{ heading: React.ReactNode; collapse?: boolean } & FlexProps> = ({
  heading,
  collapse = true, // whether the children should move below heading at extra small width
  children,
  ...props
}) => (
  <PanelPadding>
    <Flex
      flexDirection={collapse ? ['column', 'row'] : 'row'}
      alignItems={collapse ? ['flex-start', 'center'] : 'center'}
      justifyContent="space-between"
      flexWrap="wrap"
      sx={{ gap: '12px' }}
      {...props}
    >
      <SidebarPanelHeading>
        {heading}
      </SidebarPanelHeading>
      <Box>
        {children}
      </Box>
    </Flex>
  </PanelPadding>
);

export const PanelPadding: React.FC<BoxProps> = (props) => (
  <Box p={DEFAULT_PANEL_PADDING} {...props} />
);

export type PanelProps = {
  heading?: React.ReactNode;
  headingDescription?: React.ReactNode;
  style?: React.CSSProperties;
  padded?: boolean;
} & CardProps;

export const Panel = React.forwardRef(({
  heading = '',
  headingDescription,
  padded,
  children,
  variant = 'white',
  ...props
}: PanelProps, ref) => (
  <Card ref={ref} variant={variant} {...props}>
    {heading && (
      <>
        <PanelHeading description={headingDescription}>{heading}</PanelHeading>
        <PanelDivider />
      </>
    )}
    {padded ? (
      <PanelPadding>{children}</PanelPadding>
    ) : (
      children
    )}
  </Card>
));

type FlexPanelHeaderProps = {
  heading: React.ReactNode;
  children?: React.ReactNode;
  childrenContainerProps?: any;
  sx?: FlexProps['sx'];
};

export const FlexPanelHeader = ({ heading, children, childrenContainerProps = {}, sx }: FlexPanelHeaderProps) => {
  return (
    <Flex
      pt="1px"
      pb="2px"
      minHeight="60px"
      width="100%"
      justifyContent="space-between"
      sx={sx}
    >
      <Box flex="0 0 auto">
        <PanelHeading>
          {heading}
        </PanelHeading>
      </Box>
      <Flex
        flexWrap="wrap"
        alignItems="center"
        justifyContent="flex-end"
        flex={1}
        mr={3}
        my={1}
        {...childrenContainerProps}
      >
        {children}
      </Flex>
    </Flex>
  );
};
